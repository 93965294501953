// Base styles that aren't a component, a utility, a reset thing, etc...

html {
  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
    }
  }
}

body {
  // opacity: 0;
  // transition: opacity 0.1s;
  // overscroll-behavior: none;
}

.errorPage__container {
  background: white;
  width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorPage__title {
  padding: rem(28px);
}

// TODO nav open
// main {
  
// }
// body {
//   &.imageDull {
//     main {
//       opacity: 0.3;
//     }
//   }
// }
