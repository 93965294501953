@use 'sass:math';

// Misc utilities

@function em($px-value, $parent-font-px-size: $root-font-base-size) {
  @return (math.div($px-value, $parent-font-px-size) * 1em);
}

@function rem($px-value) {
  @return (math.div($px-value, $root-font-base-size) * 1rem);
}

@mixin hover {
  @include mouse-device {
    &:hover {
      @content;
    }
  }
}

@mixin mouse-device {
  @media (hover: hover) {
    @content;
  }

  @media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE11 doesn't support @media(hover), so it just gets the mouse behaviour
    @content;
  }
}

@mixin touch-device {
  @media (hover: none) {
    @content;
  }
}


@mixin visually-hidden {
  position: absolute !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0 0 0 0) !important;
}

@mixin no-js-hide {
  .no-js & {
    display: none !important;
  }
}

@mixin js-hide {
  .js & {
    display: none !important;
  }
}

@mixin fit-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

