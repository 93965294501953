//
// Anchor styles
//

// Allows more control over the placement of the underline.
@mixin underline($color: currentColor, $weight: 0.075em, $offset: 0) {
  text-decoration: none;
  background-image: unquote('linear-gradient(') to top, transparent $offset, $color $offset,
    $color ($offset + $weight), transparent ($offset + $weight) unquote(')');
}

//
// Anchor styles
//

@mixin clear-anchor-style {
  background-image: none;
  text-decoration: none;

  @include hover {
    background-image: none;
  }

  &.currentPage {
    background-image: none;

    &.relatedHovered {
      background-image: none;
    }
  }
}

// By default:
// 1. Links are underlined, except for links to the currentPage (see
//    anchors.js);
// 2. The underline is removed on hover;
// 3. Links to the currentPage are underlined if a related link (see
//    anchors.js) is hovered.
@mixin default-anchor-style(
  $color: currentColor,
  $weight: 1px,
  $offset: 0,
  $include-current: true
) {
  @include underline($color: $color, $weight: $weight, $offset: $offset);
  text-decoration: none;

  @include hover {
    background-image: none;
  }
  @if $include-current {
    &.currentPage {
      background-image: none;

      &.relatedHovered {
        @include underline($color: $color, $weight: $weight, $offset: $offset);
      }
    }
  }
}

// The inverse style, typically used for headers and navs:
// 1. Links are not underlined, except for links to the currentPage (see
//    anchors.js);
// 2. An underline is added on hover;
// 3. The underline on the link to the currentPage is removed if a related
//    link (see anchors.js) is hovered.
@mixin inverse-anchor-style(
  $color: currentColor,
  $weight: 1px,
  $offset: 0,
  $include-current: true
) {
  background-image: none;
  text-decoration: none;

  @include hover {
    @include underline($color: $color, $weight: $weight, $offset: $offset);
  }

  @if $include-current {
    &.currentPage {
      @include underline($color: $color, $weight: $weight, $offset: $offset);

      &.relatedHovered {
        background-image: none;
      }
    }
  }
}

@mixin opacity-anchor-style($include-current: true) {
  @include hover {
    opacity: $standard-opacity;
  }
  @if $include-current {
    &.currentPage {
      opacity: $standard-opacity;

      &.relatedHovered {
        opacity: 1;
      }
    }
  }
}

@mixin inverse-opacity-anchor-style($include-current: true) {
  opacity: $standard-opacity;
  @include hover {
    opacity: 1;
  }
  @if $include-current {
    &.currentPage {
      opacity: $standard-opacity;

      &.relatedHovered {
        opacity: $standard-opacity;
      }
    }
  }
}

@mixin color-anchor-style($color, $include-current: true) {
  @include hover {
    color: $color;
  }
  @if $include-current {
    &.currentPage {
      color: $color;

      &.relatedHovered {
        color: inherit;
      }
    }
  }
}
