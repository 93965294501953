@import '@/scss/_utilities';

// $line-color: cyan;
$line-color: cyan;
$line-color-reverse: magenta;

.container {
    // @include container;
    @include gutters($multiplier: 1);
    @include fixed;
    // position: absolute;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow-y: hidden;
    // display: none;
    z-index: 1000;
    will-change: transform opacity;
    transform: translate3d(0, 0, 0);

  .verticalContainer {
    display: flex;
    @include fit-parent;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   left: 50%;
    //   border-left: 1px solid rgba(pink, .5);
    // }
  }
  .vertical {
    width: columns(1);
    @include gutters;
    // float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // height: 100vh;
    @if var(--standard-gutter) != 0 {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-left: 1px solid rgba($line-color, .2);
        border-right: 1px solid rgba($line-color, .2);
      }
    } @else {
      border-left: 1px solid rgba($line-color, .2);
    }
  }
  &.reverse {
    .vertical {
      @if var(--standard-gutter) != 0 {
        &::before {
          border-left: 1px solid rgba($line-color-reverse, .2);
          border-right: 1px solid rgba($line-color-reverse, .2);
        }
      } @else {
        border-left: 1px solid rgba($line-color-reverse, .2);
      }
    }
  }
}
