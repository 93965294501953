@use 'sass:math';

:root {
  --vh: 100vh;
  --vw: 100vw;
  --standard-columns: 12;
  --standard-gutter: 1.5vw;
  --standard-inset: 1.5vw;

  // default settings for padding/margins
  --space-small: #{rem(100px)};
  --space: #{rem(160px)};

  --pageTransitionTime: 8000ms;
  

  --header-height: ((var(--standard-gutter) * 4) + 1rem);
  // --logo-ratio: #{math.div($svg-height, $svg-width)};

  --contact-image-height: 120vh;

  @include _sm {
    --standard-columns: 4;
    --standard-gutter: 2vw;
    --standard-inset: 3vw;

    --space: #{rem(100px)};
    --space-small: #{rem(30px)};
  }
  @include md_ {
    --pageTransitionTime: 1000ms;
  }
}
