@import "@/scss/_utilities";

.root {
  min-height: 100vh;
}

.main {
  // position: relative;
  // z-index: 10;
  // padding-top: calc(var(--header-height));
  background-color: var(--site-background);
  transition: background-color 300ms;
  position: relative;
}

.hamburger {
  // TODO phone
  position: sticky;
  top: 0px;
  padding-top: var(--standard-gutter);
  right: calc(var(--standard-inset) + var(--standard-gutter));
  height: 0;
  margin: 0 0 0 auto;
  float: right;
  z-index: 100;
}

.page {
  position: relative;
  padding-top: 1px;
}
