// =============================================================================
// Custom properties (variables) for custom colors, including the brand colors,
// primary and secondary palettes.
// =============================================================================

:root {
  --color-primary: #000000;
  --color-white: #ffffff;
  --color-white-alpha: #{rgba(#ffffff, 0.95)};

  --color-image-placeholder: #000000;
}
