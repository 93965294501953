@mixin container($property: margin) {
  @if ($property == margin) or ($property == padding) {
    #{$property}-left: var(--standard-inset);
    #{$property}-right: var(--standard-inset);
  } @else {
    #{$property}: var(--standard-inset);
  }
}

@function columns(
  $requested-columns,
  $parent-columns: var(--standard-columns),
  $gutter-multiplier: 2,
  $unit: '%'
) {
  $factor: ($parent-columns / var(--standard-columns));
  @if $gutter-multiplier == 0 {
    @return calc((100% * (($factor * $requested-columns) / $parent-columns)));
  } @else {
    @return calc((100% * (($factor * $requested-columns) / $parent-columns)) - (var(--standard-gutter) * $gutter-multiplier));
  }
}

@mixin gutters($property: margin, $multiplier: 1) {
  @if ($property == margin) or ($property == padding) {
    #{$property}-left: calc(var(--standard-gutter) * $multiplier);
    #{$property}-right: calc(var(--standard-gutter) * $multiplier);
  } @else {
    #{$property}: calc(var(--standard-gutter) * $multiplier);
  }
}

@mixin grid-standard {
  display: grid;
  grid-template-columns: repeat(var(--standard-cols), minmax(0, 1fr));
  @include grid-standard-col-gaps;
}

@mixin grid-standard-col-gaps {
  column-gap: var(--standard-gutter);
}

@mixin fillGrid {
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
  grid-column-end: -1;
}

@mixin fixed {
  position: fixed;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@mixin fit-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
