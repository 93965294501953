@use 'sass:math';

@mixin vw-font-size($px-size, $min-px-size: null, $max-px-size: null, $design-width) {
  $unitless-vw-size: math.div($px-size, $design-width) * 100;
  $scaled-size: #{$unitless-vw-size + 'vw'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}
@mixin vh-font-size($px-size, $min-px-size: null, $max-px-size: null, $design-height) {
  $unitless-vh-size: math.div($px-size, $design-height) * 100;
  $scaled-size: #{$unitless-vh-size + 'vh'};

  font-size: clamp($min-px-size, $scaled-size, $max-px-size);
}

